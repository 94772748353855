import { dataLayerClaimEventTrack } from './GTM_helper';

export function focusOnErrorElement(time = 0) {
  setTimeout(() => {
    const allElements = document.getElementsByTagName('*');
    let el;
    for (let index = 0; index < allElements.length; index++) {
      if (allElements[index].getAttribute('formik-error') === '1') {
        el = allElements[index];
        break;
      }
    }

    if (el) {
      el.focus();
      const windowScrollY =
        window.scrollY || document.documentElement.scrollTop;
      const focusErrorScroll =
        el.getBoundingClientRect().top + windowScrollY - 200;
      window.scrollTo(0, focusErrorScroll);
      return;
    }
  }, time);
}

export function getAllElementsWithAttribute(attribute) {
  var matchingElements = [];
  var allElements = document.getElementsByTagName('*');
  for (var i = 0, n = allElements.length; i < n; i++) {
    if (allElements[i].getAttribute(attribute) !== null) {
      // Element exists with attribute. Add to array.
      matchingElements.push(allElements[i]);
    }
  }
  return matchingElements;
}

export const clickChat = () => {
  const buttonSofia = document.getElementsByClassName('helpButtonEnabled')[0];
  if (buttonSofia) {
    buttonSofia.click();
  }
};

export function trackFieldErrorElementToGTM(event, type, step, time = 0) {
  setTimeout(() => {
    const allElements = document.getElementsByTagName('*');
    let el;
    for (let index = 0; index < allElements.length; index++) {
      if (allElements[index].getAttribute('formik-error') === '1') {
        el = allElements[index];
        break;
      }
    }

    if (el) {
      dataLayerClaimEventTrack(
        event,
        type,
        step,
        el.getAttribute('name') || el.getAttribute('calendar')
      );
      return;
    }
  }, time);
}

export function focusElement(id) {
  const element = document.getElementById(id);
  if (element) {
    element.focus();
    const windowScrollY = window.scrollY || document.documentElement.scrollTop;
    const focusErrorScroll =
      element.getBoundingClientRect().top + windowScrollY - 200;
    window.scrollTo(0, focusErrorScroll);
  }
}
