let cancelRequest;
let cancelRequestBenefits;
let cancelRequestBenefitsDetails;
const productsAndBenefits = `/productsAndBenefits`;
const message = 'Cancelation';

const BENEFIT_AS_OF_DATE = process.env.REACT_APP_BENEFIT_AS_OF_DATE.toString();

export default (env, axios, errorHandler) => ({
  getProductsByCountry: async (
    country = 'BS',
    idiom = 'en-US',
    cancellable = true
  ) => {
    const CancelToken = axios.CancelToken;

    const url = `${productsAndBenefits}/productsByCountry/${country}`;
    const data = {
      country: country,
      idiom: idiom,
    };
    const cacheKey = url + country + idiom;
    if (cancelRequest && cancellable) {
      cancelRequest(message);
    }
    return errorHandler(
      axios.cachePost(cacheKey)(url, data, {
        cancelToken: new CancelToken(function executor(cancel) {
          cancelRequest = cancellable ? cancel : cancelRequest;
        }),
      }),
      'getProductsByCountry',
      { url, idiom }
    );
  },
  getProductDetailByBinCode: async (bin_code, idiom = 'en-US') => {
    const url = `${productsAndBenefits}/productDetailByBinCode/${bin_code}`;
    const data = {
      product: bin_code,
      idiom: idiom,
    };

    const cacheKey = url;
    return errorHandler(
      axios.cachePost(cacheKey)(url, data),
      'getProductDetailByBinCode',
      { url, bin_code }
    );
  },
  getBenefitsByProduct: async (
    product,
    idiom = 'en-US',
    cancellable = true,
    asOfDate = true,
    date
  ) => {
    const CancelToken = axios.CancelToken;
    const data = {
      idiom: idiom,
      product: product,
    };
    let url = `${productsAndBenefits}/${product}/benefits`;

    if (asOfDate && BENEFIT_AS_OF_DATE) {
      url = url + `?as_of_date=${date || BENEFIT_AS_OF_DATE}`;
    }
    const cacheKey = url + idiom;
    if (cancelRequestBenefits && cancellable) {
      cancelRequestBenefits(message);
    }
    return errorHandler(
      axios.cachePost(cacheKey)(url, data, {
        cancelToken: new CancelToken(function executor(cancel) {
          cancelRequestBenefits = cancellable ? cancel : cancelRequestBenefits;
        }),
      }),
      'getBenefitsByProduct',
      { url, product, idiom }
    );
  },
  getBenefitsByProductV2: async (
    product,
    idiom = 'en-US',
    cancellable = true,
    asOfDate = true,
    date
  ) => {
    const CancelToken = axios.CancelToken;
    const data = {
      idiom: idiom,
      product: product,
    };
    let url = `${productsAndBenefits}/v2/${product}/benefits`;

    if (asOfDate && BENEFIT_AS_OF_DATE) {
      url = url + `?as_of_date=${date || BENEFIT_AS_OF_DATE}`;
    }
    const cacheKey = url + idiom;
    if (cancelRequestBenefits && cancellable) {
      cancelRequestBenefits(message);
    }
    return errorHandler(
      axios.cachePost(cacheKey)(url, data, {
        cancelToken: new CancelToken(function executor(cancel) {
          cancelRequestBenefits = cancellable ? cancel : cancelRequestBenefits;
        }),
      }),
      'getBenefitsByProductV2',
      { url, product, idiom }
    );
  },
  getBenefitsDetailByProduct: async (
    product,
    benefit,
    idiom = 'en-US',
    cancellable = true
  ) => {
    const CancelToken = axios.CancelToken;
    const url = `${productsAndBenefits}/detailBenefitsByProduct/newRedeemInfo/${product}/${benefit}`;
    const data = {
      idiom: idiom,
      benefit: benefit,
      product: product,
    };
    const cacheKey = url + idiom;
    if (cancelRequestBenefitsDetails && cancellable) {
      cancelRequestBenefitsDetails(message);
    }
    return errorHandler(
      axios.cachePost(cacheKey)(url, data, {
        cancelToken: new CancelToken(function executor(cancel) {
          cancelRequestBenefitsDetails = cancellable
            ? cancel
            : cancelRequestBenefitsDetails;
        }),
      }),
      'getBenefitsDetailByProduct',
      { url, idiom }
    );
  },
  getBenefitsDetailByProductV2: async (
    product,
    benefit,
    idiom = 'en-US',
    cancellable = true
  ) => {
    const CancelToken = axios.CancelToken;
    const url = `${productsAndBenefits}/detailBenefitsByProduct/v2/newRedeemInfo/${product}/${benefit}`;
    const data = {
      idiom: idiom,
      benefit: benefit,
      product: product,
    };
    const cacheKey = url + idiom;
    if (cancelRequestBenefitsDetails && cancellable) {
      cancelRequestBenefitsDetails(message);
    }
    return errorHandler(
      axios.cachePost(cacheKey)(url, data, {
        cancelToken: new CancelToken(function executor(cancel) {
          cancelRequestBenefitsDetails = cancellable
            ? cancel
            : cancelRequestBenefitsDetails;
        }),
      }),
      'getBenefitsDetailByProduct',
      { url, idiom }
    );
  },
});
