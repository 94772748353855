export const getRelationship = (relationship, intl) => {
  switch (relationship) {
    case 'Son':
      return intl.RELATIONSHIP_SON;
    case 'Daughter':
      return intl.RELATIONSHIP_DAU;
    case 'Spouse':
      return intl.RELATIONSHIP_SPOUSE;
    case 'Other':
      return intl.RELATIONSHIP_OTHER;
    default:
      return '-';
  }
};

export const getTitle = (title, intl) => {
  switch (title) {
    case 'MR':
      return intl.LABEL_MISTER;
    case 'MS':
      return intl.LABEL_MS;
    case 'MISS':
      return intl.LABEL_MISS;
    case 'DR':
      return intl.LABEL_DR;
    case 'PR':
      return intl.LABEL_PR;
    default:
      return intl.LABEL_MISTER;
  }
};
