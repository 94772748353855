import { useState, useContext, ReactElement } from 'react';
import { productsAndBenefitsServices } from '../../../services';
import { IntlContext } from '../../../intl';
import * as translations from './intl';
import styles from './ListCardItem.module.scss';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Button from '../../../componentsV2/Button/Button';
import Loading from '../../Loading/Loading';
import ButtonBenefits from './ButtonBenefits/ButtonBenefits';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import sanitize from '../../../utils/sanitize';
import ChevronGeneric from '../../../assets/icons/ChevronGeneric';
import { AppInsightTrackContext } from '../../AppInsightTrackContextParent/AppInsightTrackContextParent';
import { StateContext } from '../../StateContextParent/StateContextParent';
import { ENABLED_LEGACY_LINKS } from '../../../utils/environments_variables';
import { BenefitDetail, BenefitFormatted } from '../../../@types/APIs/benefit';
import { CertificateFormated } from '../../../@types/APIs/Certificate';
import { ClaimFormatted } from '../../../@types/APIs/claim';

const logUserEvent = (trackEventUserAction, type, label = 'LINK') => {
  trackEventUserAction(
    `#### (LIST CARD ITEM) CLICANDO NO ${label} DE GERAR CLAIM/CERTIFICADO`,
    type
  );
};

export interface ListCardItemProps {
  benefit: BenefitFormatted | CertificateFormated | ClaimFormatted;
  benefitsList?: BenefitFormatted[];
  category: string;
  image: string;
  description: any;
  type: 'benefits' | 'certificate' | 'claim';
  isPDF?: string | boolean;
  productBinOrPan?: string;
}

const ListCardItem = (props: ListCardItemProps) => {
  const {
    benefit,
    benefitsList,
    category,
    image,
    description,
    type,
    isPDF,
    productBinOrPan = '',
  } = props;

  const [showContent, setShowContent] = useState(false);
  const [loading, setLoading] = useState(true);
  const [benefitDetailHTML, setBenefitDetailHTML] = useState<ReactElement>(
    <></>
  );
  const [benefitDetail, setBenefitDetail] = useState(null);

  const { translate, idiomForApi, idiom, country } = useContext(IntlContext);
  const { trackEventUserAction } = useContext(AppInsightTrackContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const { utils } = useContext(StateContext);
  const history = useHistory();

  const intl = translate(translations);
  const isBR = utils.getCountry() === 'BRAZIL';
  const theme = getGlobalTheme();
  const CONFIG_CARD = {
    claim: {
      linkName: intl.TEXT_MAKE_CLAIM,
      url: `/claim-type/${
        (benefit as ClaimFormatted).external_reference
      }/generate`,
    },
    certificate: {
      linkName: !isPDF ? intl.TITLE_GENERATE_CERTIFICATE : intl.TEXT_VIEW_PDF,
      url: !isPDF
        ? `/certificate/${(benefit as CertificateFormated).code}`
        : (benefit as CertificateFormated).urlPDF,
    },
    benefits: {
      linkName: !isPDF ? intl.TITLE_GENERATE_CERTIFICATE : intl.TEXT_VIEW_PDF,
      url: !isPDF
        ? `/certificate/${(benefit as BenefitFormatted).code}`
        : (benefit as BenefitFormatted).urlPDF,
    },
  };

  const CONFIG_CARD_EXCEPTION = {
    Teleconsultation: {
      linkName: intl.BOOKING,
      url: isBR
        ? `/claim-type/${
            (benefit as BenefitFormatted).external_reference
          }/generate`
        : '/download-app/store',
    },
  };

  useEffect(() => {
    setShowContent(false);
  }, [category]);

  const setVisualization = async status => {
    setShowContent(status);
  };

  const CONFIG_NAME_CERTIFICATE = {
    billete_auto_rental_insurance: {
      name: intl.TITLE_AUTO_RENTAL_CERTIFICATE,
    },
    certificate_seguro_compra: {
      name: intl.TITLE_PURCHASE_CERTIFICATE,
    },
  };

  const formatName = benefit => {
    if (!isPDF || type !== 'certificate')
      return benefit.name && benefit.name == 'Bilhete seguro viagem'
        ? 'Bilhete Seguro Viagem'
        : benefit.name &&
          benefit.name == 'Bilhete seguro de garantia estendida original'
        ? 'Bilhete Garantia Estendida Original'
        : benefit.name;
    return CONFIG_NAME_CERTIFICATE[benefit.code].name &&
      CONFIG_NAME_CERTIFICATE[benefit.code].name ==
        'Resumo de benefícios para veículo de locadora'
      ? 'Resumo Seguro para Veículos de Locadora'
      : CONFIG_NAME_CERTIFICATE[benefit.code].name &&
        CONFIG_NAME_CERTIFICATE[benefit.code].name ==
          'Resumo de benefícios de proteção de compra ampliada'
      ? 'Resumo Proteção de Compra'
      : CONFIG_NAME_CERTIFICATE[benefit.code].name
      ? CONFIG_NAME_CERTIFICATE[benefit.code].name
      : benefit.name;
  };

  const getBenefitName = benefit => {
    const benefitFilter =
      benefitsList &&
      benefitsList.find(
        benefitFilter =>
          (benefitFilter as any).external_reference ===
          benefit.external_reference
      );

    return benefitFilter ? formatName(benefitFilter) : formatName(benefit);
  };

  const actionButton = () => {
    if ((benefit as any).urlPDF && (benefit as any).urlPDF.length > 0) {
      const newWindow = window.open();
      newWindow.opener = null;
      newWindow.location = (benefit as any).urlPDF;
    } else {
      logUserEvent(trackEventUserAction, CONFIG_CARD[type], 'BOTÃO');
      history.push(CONFIG_CARD[type].url);
    }
  };

  const navigatePDF = () => {
    if (benefitDetail.documents && benefitDetail.documents.length > 0) {
      const correctDocument = benefitDetail.documents.find(
        doc => doc.name === 'pdf_url'
      );
      if (correctDocument) {
        const newWindow = window.open();
        newWindow.opener = null;
        newWindow.location = correctDocument.content_url;
      }
    }
  };

  const showDescriptionBenefits = async () => {
    try {
      let benefitId =
        (benefit as any).benefit_id || (benefit as any).external_reference;
      if (type === 'claim' && !benefitId) {
        const formattedBenefit = benefitsList.find(
          benefitItem =>
            (benefitItem as any).external_reference ===
            (benefit as any).external_reference
        );
        benefitId = (formattedBenefit as any).benefit_id;
      }

      setVisualization(true);
      const response =
        await productsAndBenefitsServices.getBenefitsDetailByProduct(
          productBinOrPan,
          benefitId,
          idiomForApi(),
          false
        );
      const benefitDetail: BenefitDetail = response.data;

      setBenefitDetail(benefitDetail);
      setBenefitDetailHTML(htmlContent(benefitDetail));
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const htmlContent = (data: BenefitDetail) => {
    return (
      <>
        <p
          dangerouslySetInnerHTML={{ __html: sanitize(data.short_description) }}
        />
        {data &&
          data.redeem_info &&
          data.redeem_info.bluebox &&
          data.redeem_info.bluebox.subtitle && (
            <p
              className={styles.redeemInfo}
              dangerouslySetInnerHTML={{
                __html: sanitize(data.redeem_info.bluebox.subtitle),
              }}
            />
          )}
        <span className={styles.buttonWrapper}>
          <button type="button" onClick={() => setVisualization(false)}>
            <span className={styles.iconChevron}>
              <ChevronGeneric
                aria-label={intl.OPEN_BENEFIT_DETAILS}
                rotate={180}
                width={20}
                height={20}
                data-testid={`chevron-down-close-${
                  (benefit as BenefitFormatted).external_reference
                }`}
              />
            </span>
          </button>
        </span>
        <div className={`${styles[theme]} ${styles.actions}`}>
          <ButtonBenefits
            benefit={data}
            idiom={idiom}
            country={country}
            withTextUpsell
          />
        </div>
      </>
    );
  };

  const createLinkByType = () => {
    const isException =
      (benefit as BenefitFormatted).external_reference === 'Teleconsultation';
    if (
      type === 'benefits' &&
      (benefit as BenefitFormatted).code === undefined &&
      !isException
    ) {
      return <></>;
    }

    const configException =
      CONFIG_CARD_EXCEPTION[(benefit as BenefitFormatted).external_reference];

    return (
      <Button
        ariaLabel={CONFIG_CARD[type].linkName}
        className={styles.linkButton}
        type="Tertiary"
        trailingIcon="Chevron Right"
        label={
          isException ? configException.linkName : CONFIG_CARD[type].linkName
        }
        onClick={() => {
          logUserEvent(trackEventUserAction, CONFIG_CARD[type]);
          history.push(
            isException ? configException.url : CONFIG_CARD[type].url
          );
        }}
      />
    );
  };

  return (
    <div className={`col-12 ${styles.benefitItem}`} data-testid="ListCardItem">
      <div
        className={`${styles[theme]} ${styles.item} ${
          showContent ? styles.absolute : ''
        }`}
      >
        <div className={styles.top}>
          <div className={`${styles[theme]} ${styles.category}`}>
            {category}
          </div>
          <div className={styles.Icon}>
            <img
              src={image}
              width={50}
              height={50}
              alt={intl.BENEFIT_ALT(benefit && benefit.name)}
            />
          </div>
        </div>
        <div className={styles.center}>
          <h6 className={styles[theme]}>{getBenefitName(benefit)}</h6>
        </div>
        <div
          aria-label={intl.TITLE_BENEFIT_DETAILS}
          className={`${styles.content} ${!showContent ? styles.none : ''}`}
        >
          {type !== 'benefits' && (
            <>
              {type === 'claim' && loading ? (
                <Loading />
              ) : (
                <>
                  {description}
                  <span className={styles.buttonWrapper}>
                    <button
                      type="button"
                      onClick={() => setVisualization(false)}
                      data-testid="showless"
                      className={styles[theme]}
                      aria-label={intl.TEXT_OPEN_DETAIL_BENEFIT}
                    >
                      <span className={styles.iconChevron}>
                        <ChevronGeneric rotate={180} width={20} height={20} />
                      </span>
                    </button>
                  </span>
                  <div className={styles.buttonsArea}>
                    {type === 'claim' && (
                      <div className={`${styles[theme]} ${styles.actions}`}>
                        <Button
                          type="Primary"
                          id={`btn-ViewMore-${
                            (benefit as ClaimFormatted).external_reference
                          }`}
                          onClick={navigatePDF}
                          label={intl.VIEW_MORE}
                        />
                      </div>
                    )}
                    <div className={`${styles[theme]} ${styles.actions}`}>
                      {isBR &&
                        (benefit as CertificateFormated).code &&
                        (benefit as CertificateFormated).code ===
                          'certificate_seguro_compra' &&
                        ENABLED_LEGACY_LINKS && (
                          <p
                            className={`${styles[theme]} ${styles.link}`}
                            dangerouslySetInnerHTML={{
                              __html: sanitize(
                                intl.LEGACY_CERTIFICATE_RESUME_EXTENDED_PURCHASE
                              ),
                            }}
                          />
                        )}
                      {isBR &&
                        (benefit as CertificateFormated).code &&
                        (benefit as CertificateFormated).code ===
                          'billete_auto_rental_insurance' &&
                        ENABLED_LEGACY_LINKS && (
                          <p
                            className={`${styles[theme]} ${styles.link}`}
                            dangerouslySetInnerHTML={{
                              __html: sanitize(
                                intl.LEGACY_CERTIFICATE_RESUME_RENTAL_CAR
                              ),
                            }}
                          />
                        )}
                      <Button
                        type="Primary"
                        className={styles.actionButton}
                        id={`btn-linkName${
                          (benefit as any).benefit_id ||
                          (benefit as any).code ||
                          (benefit as any).external_reference
                        }`}
                        onClick={() => actionButton()}
                        label={CONFIG_CARD[type].linkName}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}

          {type === 'benefits' && (
            <>{loading ? <Loading /> : benefitDetailHTML}</>
          )}
        </div>
        <div className={`${styles.bottom} ${showContent ? styles.none : ''}`}>
          <div className={`${styles[theme]} ${styles.generate}`}>
            {(benefit as any).urlPDF && (benefit as any).urlPDF.length > 0 ? (
              <Button
                link
                internal={false}
                type="Tertiary"
                trailingIcon="Chevron Right"
                href={(benefit as any).urlPDF}
                label={CONFIG_CARD[type].linkName}
                className={styles.linkButton}
              />
            ) : (
              createLinkByType()
            )}
          </div>
          <div className={`${styles[theme]} ${styles.actions}`}>
            <button
              type="button"
              onClick={() => {
                if (type === 'benefits') {
                  showDescriptionBenefits();
                } else if (type === 'claim') {
                  showDescriptionBenefits();
                } else {
                  setVisualization(true);
                }
              }}
              aria-label={intl.MORE_DETAILS}
              data-testid="showmore"
            >
              <span className={styles.iconChevron}>
                <ChevronGeneric
                  rotate={0}
                  width={20}
                  height={20}
                  data-testid={`chevron-down-open-${
                    (benefit as any).external_reference
                  }`}
                />
              </span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ListCardItem;
