import { useContext, useEffect, useState, useMemo, Fragment } from 'react';
import { Formik } from 'formik';
import { IntlContext } from '../../../../../intl';
import * as translations from './intl';
import { pt_BR } from '../../../../../intl/idioms';
import styles from './Dependents.module.scss';
import AddArea from './ControlArea/AddArea';
import { BUSINESS, CORPORATE, CTA } from './consts';
import { StateContext } from '../../../../../components/StateContextParent/StateContextParent';
import DependentInfo from './DependentInfo/DependentInfo';
import FormDependent from './ControlArea/FormDependent/FormDependent';
import ModalWithImage from '../../../../../components/ModalWithImage/ModalWithImage';
import ModalResult from '../../../../../components/ModalResult/ModalResult';
import { userServices } from '../../../../../services';
import ModalInformation from '../../../../../components/ModalInformation/ModalInformation';
import { listDependentInformationSchema } from './validationSchema';
import DependentAcordeon from '../../../../Profile/ProfileAccount/YourDependents/DependentAcordeon/DependentAcordeon';
import Loading from '../../../../../components/Loading/Loading';
import { ThemeContext } from '../../../../../themes/ThemeContextParent/ThemeContextParent';
import EditIcon from '../../../../../assets/icons/EditIcon';
import TrashIcon from '../../../../../assets/icons/TrashIcon';
import UserIcon from '../../../../../assets/icons/UserIcon';
import CheckBox from '../../../../../components/CheckBox/CheckBox';
import Button from '../../../../../componentsV2/Button/Button';
import { joinClassNames } from '../../../../../utils/classNames';
import { focusElement } from '../../../../../utils/elements';
import { isSameOrAfterDay } from '../../../../../utils/date';

const checkCard = (card, setCardType) => {
  const { internal_name } = card;
  if (internal_name.includes(BUSINESS)) {
    setCardType(BUSINESS);
  } else if (internal_name.includes(CTA)) {
    setCardType(CTA);
  } else if (internal_name.includes(CORPORATE)) {
    setCardType(CORPORATE);
  }
};

const Dependents = props => {
  const { translate, idiom } = useContext(IntlContext);
  const [cardType, setCardType] = useState(undefined);
  const { utils, actions } = useContext(StateContext);
  const intl = translate(translations);
  const isBrazil = idiom === pt_BR;
  const {
    initialValues,
    updateParent,
    dependentsSubmitRef,
    isProfile,
    isCardHolder,
    isLoading,
    detailedError,
    setDetailedError,
  } = props;
  const cardSelected = utils.getSelectedCard();
  const countriesToShowRelationship = isBrazil;
  const cardsToShowRelationship =
    String(cardSelected.name).includes('Platinum') ||
    String(cardSelected.name).includes('Signature');

  const dependentIndexError =
    detailedError &&
    detailedError.path &&
    String(detailedError.path).split('.')[0].match(/\d+/) &&
    Number(String(detailedError.path).split('.')[0].match(/\d+/)[0]);

  const startDateToShowRelationship = new Date(
    `${process.env.REACT_APP_BRABEN_START_DATE}T00:00:00`
  );
  const today = new Date();

  const showRelationship =
    cardSelected.category !== 'Consumer' ||
    (countriesToShowRelationship &&
      cardsToShowRelationship &&
      isSameOrAfterDay(today, startDateToShowRelationship));

  const validationSchema = useMemo(
    () =>
      listDependentInformationSchema(
        intl,
        isBrazil,
        showRelationship,
        cardSelected
      ),
    []
  );
  const { getGlobalTheme } = useContext(ThemeContext);
  const themes = getGlobalTheme();

  useEffect(() => {
    checkCard(utils.getSelectedCard(), setCardType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (dependentIndexError !== null) {
      focusElement(`Dependent-${dependentIndexError}`);
    }
  }, [dependentIndexError]);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={() => {}}
      enableReinitialize
    >
      {props => {
        const { values, handleSubmit, setFieldValue } = props;
        let newValue = values;

        updateParent(values, validationSchema);

        const mappedDependents = newValue.dependents.map(
          (currentValue, index, array) => {
            const dependentWithError =
              dependentIndexError !== null && dependentIndexError === index;

            const editDependentForm = () => {
              actions.modal.showModal(
                intl.TITLE_EDIT_DEPENDENT,
                true,
                <FormDependent
                  isUpdate
                  index={index}
                  intl={intl}
                  cardType={cardType}
                  newValue={newValue}
                  isCardHolder={isCardHolder}
                  isBrazil={isBrazil}
                  updateParent={updateParent}
                  {...props}
                  initialValuesDependent={currentValue}
                  showRelationship={showRelationship}
                  setDetailedError={setDetailedError}
                />,
                false,
                false,
                null,
                null,
                <UserIcon width={30} height={30} />,
                true,
                false,
                true
              );
            };
            const deleteDependent = () => {
              actions.modal.showModal(
                false,
                true,
                <ModalWithImage
                  type="error"
                  title={intl.TITLE_DELETE_DEPENDENT}
                  subtitle={intl.SUBTITLE_DELETE_DEPENDENT}
                >
                  <ModalResult
                    buttonConfirmType="borderRed"
                    textConfirm={intl.TEXT_YES_REMOVE}
                    textDeny={intl.TEXT_NO_KEEP_DEPENDENT}
                    clickConfirm={async () => {
                      try {
                        actions.modal.showLoading(intl.REMOVING_DEPENDENT);
                        await userServices.deleteDependent(
                          utils.getCn(),
                          currentValue.dependent_id
                        );

                        const dependents = [...newValue.dependents];
                        dependents.splice(index, 1);
                        setFieldValue('dependents', dependents);
                        actions.modal.hideLoading();

                        actions.modal.showModal(
                          false,
                          false,
                          <ModalInformation
                            type="success"
                            message={intl.SUCCESS_DELETE_DEPENDENT}
                          />,
                          true,
                          false
                        );

                        const dependentWithError =
                          dependentIndexError !== null &&
                          dependentIndexError === index;
                        if (dependentWithError && setDetailedError) {
                          setDetailedError(null);
                        }
                      } catch (error) {
                        actions.modal.hideLoading();
                        actions.modal.showModal(
                          false,
                          false,
                          <ModalInformation
                            type="error"
                            message={'Algo deu errado'}
                          />,
                          true,
                          false
                        );
                      }
                    }}
                    clickDeny={() => actions.modal.closeModal()}
                  />
                </ModalWithImage>,
                true
              );
            };
            const bodyDependent = (
              <Fragment key={`${currentValue.dependent_id}`}>
                <div
                  className={
                    isProfile
                      ? ``
                      : values.dependents[index].isSelected
                      ? `${styles.dependentContentBorder} ${styles[themes]} ${
                          dependentWithError ? styles.borderRed : ''
                        }`
                      : `${styles.dependentContent}`
                  }
                  id={`Dependent-${index}`}
                >
                  <DependentInfo
                    dependent={currentValue}
                    index={index}
                    showRelationship={showRelationship}
                    {...props}
                  />
                  {isProfile && <hr className={styles.divideFooter} />}
                  <div
                    className={joinClassNames(
                      styles.footer,
                      isProfile ? styles.paddingBottom : undefined
                    )}
                  >
                    {!isProfile && (
                      <div className={styles.selectCheckboxWrapper}>
                        <CheckBox
                          value={currentValue.isSelected}
                          onClick={() => {
                            setFieldValue(
                              `dependents[${index}].isSelected`,
                              !currentValue.isSelected
                            );
                          }}
                        >
                          {currentValue.isSelected
                            ? intl.CHECKBOX_LABEL_IS_SELECTED
                            : intl.CHECKBOX_LABEL_SELECT}
                        </CheckBox>
                      </div>
                    )}
                    <Button
                      id={`editDependentButton-${currentValue.dependent_id}`}
                      type="Tertiary"
                      onClick={editDependentForm}
                      leadingIcon={<EditIcon width={22} height={22} />}
                      label={intl.BTN_EDIT}
                    />
                    <Button
                      id={`removeDependentButton-${currentValue.dependent_id}`}
                      type="Tertiary"
                      onClick={deleteDependent}
                      leadingIcon={<TrashIcon width={24} height={24} />}
                      label={intl.BTN_REMOVE}
                    />
                  </div>
                </div>
                {dependentWithError && currentValue.isSelected && (
                  <label className={styles.errorLabel} role="alert">
                    {intl.TEXT_DEPENDENT_ERROR}
                  </label>
                )}
                {!isProfile && index + 1 < array.length && (
                  <hr className={styles.divideItem} />
                )}
              </Fragment>
            );

            if (isProfile) {
              return (
                <DependentAcordeon
                  dependent={currentValue}
                  key={`${currentValue.dependent_id}`}
                >
                  {bodyDependent}
                </DependentAcordeon>
              );
            }
            return bodyDependent;
          }
        );

        return (
          <form onSubmit={handleSubmit} className={styles.dependents}>
            <button
              ref={dependentsSubmitRef}
              type="submit"
              style={{ display: 'none' }}
            ></button>
            <div className={`row`}>
              <div
                className={`col-12 ${styles.titleArea} ${
                  !isProfile && styles.flexReverse
                }`}
              >
                {isProfile && (
                  <h2 className={styles[themes]}>{intl.YOUR_DEPENDENTS}</h2>
                )}
                <AddArea
                  intl={intl}
                  cardType={cardType}
                  newValue={newValue}
                  isCardHolder={isCardHolder}
                  isBrazil={isBrazil}
                  updateParent={updateParent}
                  showRelationship={showRelationship}
                  {...props}
                />
              </div>
            </div>
            {isLoading ? (
              <Loading />
            ) : (
              <div>
                {mappedDependents}
                {newValue.dependents.length === 0 && (
                  <p className={`${styles.emptyMessage} ${styles[themes]}`}>
                    {intl.TEXT_NO_DEPENDENTS}
                  </p>
                )}
              </div>
            )}
          </form>
        );
      }}
    </Formik>
  );
};

export default Dependents;
