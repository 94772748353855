import { useContext, useEffect, useState } from 'react';
import styles from '../BenefitsDetail.module.scss';
import { IntlContext } from '../../../intl';
import * as translations from '../intl';
import { claimsServices, certificatesServices } from '../../../services/index';
import { StateContext } from '../../../components/StateContextParent/StateContextParent';
import { BenefitDetailV2 } from '../../../@types/APIs/benefit.d';
import BlueBoxMarketing from '../../DownloadApp/Components/BlueBoxMarketing/BlueBoxMarketing';
import BlueBoxDefault from '../blueBoxDefault/BlueBoxDefault';
import Loading from '../../../components/Loading/Loading';

interface BenefitBlueboxProps {
  benefitDetail: BenefitDetailV2;
  isDownloadType?: boolean;
  cardHasVMO: boolean;
}

const matchListClaim = ({ data }, external_reference) => {
  const listFiltered = data.find(
    res =>
      res.external_reference === external_reference ||
      res.benefit_code === external_reference
  );
  return listFiltered;
};

const matchListCertificate = ({ data }, external_reference) => {
  const listFiltered = data.find(res =>
    res.covered_benefits_references.includes(external_reference)
  );
  return listFiltered;
};

const loadCertificateClaim = async (
  external_reference,
  setListCertificate,
  setListClaim,
  idiom,
  binPan,
  setLoading
) => {
  setLoading(true);
  try {
    const listCertificateTypes =
      await certificatesServices.listCertificateTypesByBinNumber(binPan, idiom);
    const filteredCertificate = matchListCertificate(
      listCertificateTypes,
      external_reference
    );
    setListCertificate(filteredCertificate);
  } catch (error) {
    console.log(error);
  }

  try {
    const listClaimTypes = await claimsServices.getClaimTypes(binPan, idiom);
    const filteredClaim = matchListClaim(listClaimTypes, external_reference);
    setListClaim(filteredClaim);
  } catch (error) {
    console.log(error);
  }
  setLoading(false);
};

const BenefitBluebox = ({
  benefitDetail,
  isDownloadType,
  cardHasVMO,
}: BenefitBlueboxProps) => {
  const [certificate, setCertificate] = useState(null);
  const [claim, setClaim] = useState(null);
  const [loading, setLoading] = useState(false);
  const { utils } = useContext(StateContext);
  const { translate, idiom } = useContext(IntlContext);

  const isLogged = utils.isUserLogged();
  const intl = translate(translations);

  useEffect(() => {
    if (isLogged && benefitDetail && benefitDetail.benefit_code) {
      loadCertificateClaim(
        benefitDetail.benefit_code,
        setCertificate,
        setClaim,
        idiom,
        utils.getBin(true),
        setLoading
      );
    }
  }, [benefitDetail]);

  if (loading) {
    return <Loading color={undefined} checkBoxLoading={undefined} />;
  } else if (isDownloadType) {
    return (
      <div className={styles.blueBoxContent}>
        <BlueBoxMarketing
          subtitle={intl.ACTIVE_DOWNLOAD_BENEFIT(benefitDetail.name)}
        />
      </div>
    );
  } else {
    return (
      <div className={styles.blueBoxContent}>
        <BlueBoxDefault
          isLogged={isLogged}
          certificate={certificate}
          claim={claim}
          benefitDetail={benefitDetail}
          cardHasVMO={cardHasVMO}
        />
      </div>
    );
  }
};

export default BenefitBluebox;
