import {
  ENABLE_CROSS_SELLING,
  B2B2C_TEST_WHITELIST,
} from './environments_variables';

export const ELIGIBLE_CARDS_CROSSELLING = ['GOLD', 'CLASSIC'];
const ELIGIBLE_BENEFITS = ['EMC_Emergency_Medical_Assistance'];
const ELIGIBLE_COUNTRIES = ['MEXICO', 'MX'];

export const existsInWhitelist = email => B2B2C_TEST_WHITELIST.includes(email);

export const enableCrosselling = (cardName, country, email) => {
  if (ENABLE_CROSS_SELLING) {
    return verifyEligibleCards(cardName) && enableCrossellingByCountry(country);
  } else {
    return (
      verifyEligibleCards(cardName) &&
      enableCrossellingByCountry(country) &&
      existsInWhitelist(email)
    );
  }
};

export const enableCrossellingByCountry = country => {
  return ELIGIBLE_COUNTRIES.includes(country);
};

const verifyEligibleCards = name => {
  const stringToArray = name && name.toUpperCase().split(' ');
  if (!stringToArray) {
    return false;
  }
  const checkEligibleCards = ELIGIBLE_CARDS_CROSSELLING.find(card =>
    stringToArray.includes(card)
  );
  return checkEligibleCards;
};

const verifyEligibleBenefits = name => {
  return ELIGIBLE_BENEFITS.includes(name);
};

export const checkAcessCross = (benefit, card, email) => {
  const benefitName =
    benefit && (benefit.external_reference || benefit.benefit_code)
      ? benefit.external_reference || benefit.benefit_code
      : benefit;

  if (ENABLE_CROSS_SELLING) {
    return (
      verifyEligibleCards(card.name) &&
      enableCrossellingByCountry(card.country) &&
      verifyEligibleBenefits(benefitName)
    );
  } else {
    return (
      verifyEligibleCards(card.name) &&
      enableCrossellingByCountry(card.country) &&
      verifyEligibleBenefits(benefitName) &&
      existsInWhitelist(email)
    );
  }
};

export const verifyAccess = (benefit, card, email) => {
  return checkAcessCross(benefit, card, email);
};

export const verifyAcessRenderButton = (benefit, card, email) => {
  return checkAcessCross(benefit, card, email);
};
