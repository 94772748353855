/* eslint-disable no-console */
import React, { useMemo, useContext, useState, useEffect } from 'react';
import { Formik } from 'formik';
import PersonalArea from '../../PersonalArea/PersonalArea';
import RegistrationsArea from '../../RegistrationsArea/RegistrationsArea';
import { dependentInformationSchema } from '../../validationSchema';
import styles from './FormDependent.module.scss';
import Button from '../../../../../../../components/Button/Button';
import { StateContext } from '../../../../../../../components/StateContextParent/StateContextParent';
import { userServices } from '../../../../../../../services';
import {
  dependentCreate,
  dependentsDataUpdate,
} from '../../../submit/checkDependetsChange/checkDependetsChange';
import ModalInformation from '../../../../../../../components/ModalInformation/ModalInformation';
import { dataLayerBeneficiaryTrack } from '../../../../../../../utils/GTM_helper';
import Loading from '../../../../../../../components/Loading/Loading';

const FormDependent = props => {
  const {
    initialValuesDependent,
    intl,
    isBrazil,
    newValue,
    setFieldValue,
    cardType,
    isUpdate,
    index,
    showRelationship,
    setDetailedError,
  } = props;
  const { actions, utils } = useContext(StateContext);
  const [cardholderInfo, setCardholderInfo] = useState({});
  const [loadingCardholder, setLoadingCardholder] = useState(true);
  const cn = utils.getCn();

  const validationSchemaDependent = useMemo(
    () =>
      dependentInformationSchema(
        intl,
        isBrazil,
        showRelationship,
        utils.getSelectedCard()
      ),
    [intl, isBrazil]
  );

  const loadCardholderInfo = async cn => {
    try {
      setLoadingCardholder(true);
      const { data } = await userServices.getCHInfo(cn);
      setCardholderInfo(data);
    } catch (error) {
      setCardholderInfo({});
    }
    setLoadingCardholder(false);
  };

  const addDependent = async values => {
    try {
      actions.modal.showLoading(intl.SAVING_DEPENDENT);
      const dataToCreate = dependentCreate([values], cardholderInfo);

      const create = dataToCreate.map(dependent => {
        return userServices.createCHDependents(utils.getCn(), dependent);
      });

      const response = await Promise.all(create);
      const dependent_id = response[0].data.dependent_id;

      const dependents = [...newValue.dependents];
      dependents.push({ ...values, dependent_id });
      setFieldValue('dependents', dependents);

      dataLayerBeneficiaryTrack();
      actions.modal.hideLoading();
      actions.modal.showModal(
        false,
        false,
        <ModalInformation
          type="success"
          message={intl.SUCCESS_ADD_DEPENDENT}
        />,
        true,
        false
      );
    } catch (error) {
      console.log(error);

      actions.modal.hideLoading();
      actions.modal.showModal(
        false,
        false,
        <ModalInformation type="error" message={'Algo deu errado'} />,
        true,
        false
      );
    }
  };

  const updateDependent = async values => {
    try {
      actions.modal.showLoading(intl.SAVING_DEPENDENT);
      const dataToUpdate = dependentsDataUpdate([values], cardholderInfo);

      const update = dataToUpdate.map(dependent => {
        let dependent_id = dependent.dependent_id;
        delete dependent.dependent_id;
        return userServices.updateCHDependents(
          utils.getCn(),
          dependent,
          dependent_id
        );
      });

      await Promise.all(update);

      const dependents = [...newValue.dependents];
      dependents[index] = values;
      setFieldValue('dependents', dependents);
      actions.modal.hideLoading();
      actions.modal.showModal(
        false,
        false,
        <ModalInformation
          type="success"
          message={intl.SUCCESS_UPDATE_DEPENDENT}
        />,
        true,
        false
      );
      if (setDetailedError) {
        setDetailedError(null);
      }
    } catch (error) {
      console.log(error);
      actions.modal.hideLoading();
      actions.modal.showModal(
        false,
        false,
        <ModalInformation type="error" message={'Algo deu errado'} />,
        true,
        false
      );
    }
  };

  const cancel = event => {
    event.preventDefault();
    actions.modal.closeModal();
  };

  useEffect(() => {
    loadCardholderInfo(cn);
  }, [cn]);

  if (loadingCardholder) {
    return <Loading />;
  }

  return (
    <Formik
      initialValues={initialValuesDependent}
      validationSchema={validationSchemaDependent}
      onSubmit={isUpdate ? updateDependent : addDependent}
    >
      {props => {
        const { handleSubmit } = props;

        return (
          <form className={styles.form} onSubmit={handleSubmit}>
            <PersonalArea
              {...props}
              cardType={cardType}
              showRelationship={showRelationship}
            />
            <RegistrationsArea {...props} />
            <hr />
            <div className={`${styles.footer} ${styles.section}`}>
              <Button type="borderBlue" id="borderBlue-saveDependent">
                {intl.SAVE_DEPENDENT}
              </Button>
              <Button type="blueWithoutBorder" onClick={cancel}>
                {intl.BTN_CANCEL}
              </Button>
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default FormDependent;
