import { useContext, useEffect, useRef } from 'react';
import { IntlContext } from '../../../intl/index';
import * as translations from './intl';
import { toast } from 'react-toastify';
import { showNotification } from '../utils';
import waitForPublicCard from '../waitForPublicCard';
import { isSameOrAfterDay, isSameOrBeforeDay } from '../../../utils/date';

const NotificationBenefitChanges = () => {
  const { translate, country } = useContext(IntlContext);
  const intl = translate(translations);
  const toastId = useRef(null);
  const delayId = useRef(null);

  const isBrazil = country === 'BR';

  const content = (
    <span
      dangerouslySetInnerHTML={{
        __html: intl.BENEFIT_UPDATE_TEXT,
      }}
    />
  );

  const startDate = new Date(
    `${process.env.REACT_APP_BRABEN_PREV_START_DATE}T00:00:00`
  );
  const endDate = new Date(
    `${process.env.REACT_APP_BRABEN_PREV_END_DATE}T00:00:00`
  );
  const today = new Date();

  const dateCondition =
    isSameOrAfterDay(today, startDate) && isSameOrBeforeDay(today, endDate);

  useEffect(() => {
    clearTimeout(delayId.current);
    delayId.current = null;

    if (dateCondition && isBrazil) {
      delayId.current = setTimeout(() => {
        showNotification(toastId, content);
      }, 2001);
    }

    return () => toast.dismiss(toastId.current);
  }, [country]);

  return null;
};

export default waitForPublicCard(NotificationBenefitChanges);
