import React from 'react';
import PrivateCertificatesList from '../../components/PrivateCertificatesList/PrivateCertificatesList';
import PrivateCertificatesNew from '../../components/PrivateCertificatesNew/PrivateCertificatesNew';
import NotificationCertificateChanges from '../../components/ToastNotifications/NotificationCertificateChanges/NotificationCertificateChanges';

const AllCertificates = () => {
  return (
    <>
      <NotificationCertificateChanges />
      <PrivateCertificatesList />
      <PrivateCertificatesNew />
    </>
  );
};

export default AllCertificates;
