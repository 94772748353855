export const TITLE = {
  en_US: 'Certificates',
  es_ES: 'Certificados',
  pt_BR: 'Certificados',
};
export const SUBTITLE = {
  en_US:
    'See your recent certificates below and generate a new one from your list.',
  es_ES:
    'Revisa tus certificados recientes a continuación y genere uno nuevo de tu lista.',
  pt_BR: 'Veja seus bilhetes recentes abaixo e crie um novo em sua lista.',
};

export const TEXT_MAKE_CLAIM = {
  en_US: 'Make claims',
  es_ES: 'Hacer reclamos',
  pt_BR: 'Criar reivindicações',
};

export const TEXT_CREATE_CLAIM = {
  en_US: 'Open a claim',
  pt_BR: 'Abrir um sinistro',
  es_ES: 'Crear un reclamo',
};

export const TEXT_VIEW_PDF = {
  en_US: 'View PDF',
  es_ES: 'Ver PDF',
  pt_BR: 'Ver PDF',
};

export const TEXT_VIEW_ALL = {
  en_US: 'View all certificates',
  es_ES: 'Ver todos los certificados',
  pt_BR: 'Ver todos os bilhetes',
};

export const TEXT_EXPIRY_DATE = {
  en_US: 'Valid until: ',
  es_ES: 'Válido hasta: ',
  pt_BR: 'Válido até: ',
};

export const TITLE_AUTO_RENTAL_CERTIFICATE = {
  en_US: 'Auto rental insurance',
  pt_BR: 'Bilhete Seguro para Veículos de Locadora',
  es_ES: 'Certificado seguro de alquiler de auto',
};
export const TITLE_EXTENDED_WARRANTY_CERTIFICATE = {
  en_US: 'Extended warranty',
  pt_BR: 'Bilhete Garantia Estendida Original',
  es_ES: 'Garantía extendida',
};
export const TITLE_TRAVEL_INSURANCE_CERTIFICATE = {
  en_US: 'Travel Assistance Certificate',
  pt_BR: 'Bilhete Seguro Viagem',
  es_ES: 'Certificado de Asistencia en Viaje',
};
export const TITLE_PURCHASE_CERTIFICATE = {
  en_US: 'Certificate of purchase',
  pt_BR: 'Bilhete de Proteção de Compra',
  es_ES: 'Certificado de compra',
};

export const TEXT_TICKET_NUMBER = {
  en_US: 'Ticket number',
  es_ES: 'Numero de ticket',
  pt_BR: 'Número do bilhete',
};

export const TEXT_CREATION_DATE = {
  en_US: 'Creation date',
  es_ES: 'Fecha de creatíon',
  pt_BR: 'Vigência anual',
};

export const TEXT_TRIP_DATE = {
  en_US: 'Trip date',
  es_ES: 'Fecha de viaje',
  pt_BR: 'Data de início de viagem',
};

export const TEXT_DATE_FROM = {
  en_US: 'From',
  es_ES: 'Del',
  pt_BR: 'De',
};

export const TEXT_DATE_TO = {
  en_US: 'to',
  es_ES: 'al',
  pt_BR: 'até',
};

export const TEXT_PURCHASE_BRAND = {
  en_US: 'Purchase brand',
  es_ES: 'Marca',
  pt_BR: '',
};

export const TEXT_PURCHASE_AMOUNT = {
  en_US: 'Purchase amount',
  es_ES: 'Monto de la compra',
  pt_BR: '',
};

export const TEXT_ORIGINAL_WARRANTY_PERIOD = {
  en_US: 'Original warranty period',
  es_ES: 'Período de garantía original',
  pt_BR: 'Data da compra',
};

export const TEXT_RENTAL_DATE = {
  en_US: 'Rental date',
  es_ES: 'Fecha de alquiler',
  pt_BR: '',
};

export const TEXT_ORIGIN_COUNTRY = {
  en_US: 'Origin (country)',
  es_ES: 'Origen (país)',
  pt_BR: '',
};

export const TEXT_ORIGIN_CITY = {
  en_US: 'Origin (city)',
  es_ES: 'Origen (ciudad)',
  pt_BR: '',
};

export const TEXT_DESTINATION_COUNTRY = {
  en_US: 'Destination (country)',
  es_ES: 'Destino (país)',
  pt_BR: '',
};

export const TEXT_DESTINATION_CITY = {
  en_US: 'Destination (city)',
  es_ES: 'Destino (ciudad)',
  pt_BR: '',
};

export const TEXT_PURCHASE_DATE = {
  en_US: 'Purchase date',
  es_ES: 'Fecha de compra',
  pt_BR: 'Data da compra',
};

export const TEXT_BRAND = {
  en_US: 'Brand',
  es_ES: 'Marca',
  pt_BR: '',
};

export const RENTAL_DATE = {
  en_US: 'Rental date',
  es_ES: 'Destino',
  pt_BR: '',
};

export const TO = {
  en_US: 'to',
  es_ES: 'para',
  pt_BR: '',
};

export const TEXT_SELECT = {
  en_US: 'Select',
  es_ES: 'Selecciona',
  pt_BR: 'Selecionar',
};

export const TRAVEL_DESTINATION = {
  en_US: 'Destination: ',
  pt_BR: 'Destino: ',
  es_ES: 'Destino: ',
};

export const SUBTITLE_YOUR_CERTIFICATES = {
  en_US: 'My recent certificates',
  es_ES: 'Mis certificados recientes',
  pt_BR: 'Meus bilhetes de seguro recentes',
};
