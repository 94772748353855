import React, { useContext } from 'react';
import KnowBenefits from '../../components/Sections/KnowBenefits/KnowBenefits';
import ExploreBenefits from './ExploreBenefits/ExploreBenefits';
import Banner from './banner/Banner';
import BoxCarousel from './boxCarousel/BoxCarousel';
import Products from './Products/Products';
import styles from './Home.module.scss';
import Offer from './offer/Offer';
import { PublicContext } from '../../components/PublicContextParent/PublicContextParent';
import { ENABLE_OFFERS } from '../../utils/environments_variables';
import BannerAXAMarket from './bannerAXAMarket/BannerAXAMarket';
import NotificationIEMSCertificate from '../../components/ToastNotifications/NotificationIEMSCertificate';
import NotificationBenefitChanges from '../../components/ToastNotifications/NotificationBenefitChanges/NotificationBenefitChanges';
import NotificationBenefitHaveChanged from '../../components/ToastNotifications/NotificationBenefitHaveChanged/NotificationBenefitHaveChanged';

const Home = () => {
  const { productActive, setProductActive } = useContext(PublicContext);

  return (
    <>
      {/* <AppBanner /> */}
      <NotificationBenefitChanges />
      <NotificationBenefitHaveChanged />
      <NotificationIEMSCertificate />
      <div className={styles.container}>
        <Banner>
          <BoxCarousel productActive={productActive} />
        </Banner>
        <div id="mainContent" className={styles.boxProducts}>
          <Products
            productActive={productActive}
            setProductActive={setProductActive}
          />
        </div>
        <div className={styles.wrapBanner}>
          <BannerAXAMarket />
        </div>
        {ENABLE_OFFERS && (
          <div className={styles.offerSession}>
            <Offer />
          </div>
        )}

        <div className={styles.benefitSession}>
          <ExploreBenefits productActive={productActive} />
        </div>
        <KnowBenefits />
      </div>
    </>
  );
};

export default Home;
