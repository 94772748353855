export const DEPENDENTS_MANDATORY_INFORMATION_MISSING = {
  en_US: `Additional traveler information is missing.`,
  pt_BR: 'Faltam informações sobre os viajantes adicionais.',
  es_ES: 'Falta información sobre los viajeros adicionales.',
};

export const DEPENDENTS_MANDATORY_INFORMATION_MISSING_SUBTITLE = {
  en_US: `Please go to the "Additional Travelers" section of the form and fill in the required fields before continuing.`,
  pt_BR:
    "Por favor, acesse a seção 'Viajantes Adicionais' do formulário e preencha os campos obrigatórios antes de continuar.",
  es_ES:
    'Por favor, accede a la sección "Viajeros Adicionales" del formulario y completa los campos obligatorios antes de continuar.',
};
