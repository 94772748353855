export const TEXT_TRAVEL = {
  en_US: 'Travel',
  es_ES: 'Viaje',
  pt_BR: 'Viagem',
};

export const TEXT_SHOPPING = {
  en_US: 'Shopping',
  es_ES: 'Compras',
  pt_BR: 'Compras',
};

export const TEXT_VIEW_PDF = {
  en_US: 'Generate certificate',
  es_ES: 'Generar un certificado',
  pt_BR: 'Ver PDF',
};

export const TITLE_GENERATE_CERTIFICATE = {
  en_US: 'Generate certificate',
  es_ES: 'Generar un certificado',
  pt_BR: 'Gerar certificado',
};

export const TITLE_GENERATE_CERTIFICATE_TRAVEL_INSURANCE = {
  en_US: 'Generate certificate',
  es_ES: 'Generar un certificado',
  pt_BR: 'Criar bilhete de viagem',
};

export const TITLE_GENERATE_CERTIFICATE_EXTENDED_WARRANTY = {
  en_US: 'Generate certificate',
  es_ES: 'Generar un certificado',
  pt_BR: 'Criar bilhete de seguro',
};

export const TITLE_AUTO_RENTAL_CERTIFICATE = {
  en_US: 'Auto rental insurance',
  es_ES: 'Certificado seguro de alquiler de auto',
  pt_BR: 'Resumo de benefícios para veículo de locadora',
};
export const TITLE_EXTENDED_WARRANTY_CERTIFICATE = {
  en_US: 'Extended warranty',
  es_ES: 'Garantía extendida',
  pt_BR: 'Bilhete seguro de garantia estendida original',
};
export const TITLE_TRAVEL_INSURANCE_CERTIFICATE = {
  en_US: 'Travel assistance certificate',
  es_ES: 'Certificado de asistencia en viaje',
  pt_BR: 'Bilhete seguro viagem',
};
export const TITLE_PURCHASE_CERTIFICATE = {
  en_US: 'Certificate of purchase',
  es_ES: 'Certificado de compra',
  pt_BR: 'Resumo de benefícios de proteção de compra ampliada',
};

export const TEXT_TRAVEL_INSURANCE_PROCESS_SUSEP = {
  en_US: '#This certificate covers the following benefits:',
  es_ES: '#Este certificado cubre los siguientes beneficios:',
  pt_BR:
    '#Processo SUSEP 15414.900762/2015-16 #Se a viagem iniciada durante os 12 meses de cobertura do bilhete de seguro terminar após esse período, é imprescindível emitir um novo bilhete de seguro ao término dos primeiros 12 meses para complementar a cobertura. #Este bilhete de seguro cobre os seguintes benefícios:',
};
export const TEXT_TRAVEL_INSURANCE_FOLLOWING_INFO = {
  en_US:
    'To generate this certificate you will need to provider the following information:',
  es_ES:
    'Para generar este certificado, deberás proporcionar la siguiente información:',
  pt_BR:
    'Para gerar este bilhete, você precisará fornecer as seguintes informações:',
};

export const TEXT_TRAVEL_INSURANCE_PERSONAL_INFO = {
  en_US:
    '- Information about your itinerary and baggage - Personal information - This certificate complies with the requirements of the medical assistance coverage required by the countries belonging to the Schengen treaty',
  es_ES:
    '- Información sobre tu itinerario de viaje y equipaje - Informacion personal - Este certificado cumple los requisitos de la cobertura de asistencia médica exigida por los países pertenecientes al Tratado de Schengen',
  pt_BR:
    '- Informações pessoais - Este Bilhete atende os requisitos da cobertura de assistência médica exigida pelos países pertencentes ao Tratado de Schengen',
};

export const TEXT_CERTIFICATE_EXTENDED_WARRANTY_PROCESS_SUSEP = {
  en_US: '#This certificate covers the following benefits:',
  es_ES: '#Este certificado cubre los siguientes beneficios:',
  pt_BR:
    '#Processo SUSEP 15414.900511/2014-42 #Este bilhete é válido para todos os produtos adquiridos anteriormente que ainda estejam dentro da garantia original do fabricante, bem como para todos os produtos comprados com o mesmo cartão durante o período de 12 meses após a emissão do bilhete de garantia estendida. #Este bilhete de seguro cobre os seguintes benefícios:',
};
export const TEXT_CERTIFICATE_EXTENDED_WARRANTY_FOLLOWING_INFO = {
  en_US:
    'To generate this certificate you will need to provider the following information:',
  es_ES:
    'Para generar este certificado, deberás proporcionar la siguiente información:',
  pt_BR:
    'Para gerar este bilhete, você precisará fornecer as seguintes informações:',
};
export const TEXT_CERTIFICATE_EXTENDED_WARRANTY_PERSONAL_INFO = {
  en_US:
    '- Information about the product (description, brand and model) - Personal information',
  es_ES:
    '- Información sobre el producto (descripción, marca y modelo) - Información personal',
  pt_BR: '- Informações pessoais',
};

export const TEXT_TRAVEL_BILLETE_AUTO_RENTAL_INSURANCE_PROCESS_SUSEP = {
  en_US: '#This certificate covers the following benefits:',
  es_ES: '#Este certificado cubre los siguientes beneficios:',
  pt_BR: '',
};
export const TEXT_TRAVEL_BILLETE_AUTO_RENTAL_INSURANCE_FOLLOWING_INFO = {
  en_US:
    'To generate this certificate you will need to provider the following information:',
  es_ES:
    'Para generar este certificado, deberás proporcionar la siguiente información:',
  pt_BR: '',
};
export const TEXT_TRAVEL_BILLETE_AUTO_RENTAL_INSURANCE_PERSONAL_INFO = {
  en_US: '- Information about the vehicle rental - Personal Information',
  es_ES: '- Información sobre arquiler de vehículo - Información personal',
  pt_BR: '',
};

export const TEXT_CERTIFICATE_SEGURO_COMPRA_PROCESS_SUSEP = {
  en_US: '#This certificate covers the following benefits:',
  es_ES: '#Este certificado cubre los siguientes beneficios:',
  pt_BR: '',
};
export const TEXT_CERTIFICATE_SEGURO_COMPRA_FOLLOWING_INFO = {
  en_US:
    'To generate this certificate you will need to provider the following information:',
  es_ES:
    'Para generar este certificado, deberás proporcionar la siguiente información:',
  pt_BR: '',
};
export const TEXT_CERTIFICATE_SEGURO_COMPRA_PERSONAL_INFO = {
  en_US:
    '- Information about the product (description, brand and model) - Personal information',
  es_ES:
    '- Información sobre el producto (descripción, marca y modelo) - Información personal',
  pt_BR: '',
};

export const NO_CERTIFICATE_TYPE_AVAILABLE = {
  en_US: 'No certificate type available.',
  es_ES: 'No hay ningún tipo de certificado disponible.',
  pt_BR: 'Nenhum tipo de bilhete disponível.',
};
