import { useContext, useEffect } from 'react';
import { sanitize } from 'dompurify';
import styles from '../BenefitsDetail.module.scss';
import './PrismicContent.scss';
import { ThemeContext } from '../../../themes/ThemeContextParent/ThemeContextParent';
import { IntlContext } from '../../../intl';
import * as translations from '../intl';
import { clickChat } from '../../../utils/elements';
import BenefitBlueRetangle from '../benefitBueRectangle/BenefitBlueRetangle';
import Link from '../../../componentsV2/Link/Link';
import { ENABLE_UPSELLING } from '../../../utils/environments_variables';
import { isSameOrBeforeDay, sortByValidUntil } from '../../../utils/date';

const BenefitContent = props => {
  const {
    descriptionDivs,
    benefitDetail,
    isNewVersionDetail,
    setSelectedTab,
    expectedDocuments,
  } = props;
  const { getGlobalTheme } = useContext(ThemeContext);
  const { translate, country } = useContext(IntlContext);
  const intl = translate(translations);
  const theme = getGlobalTheme();
  const isMX = country === 'MX';
  const isAfluent = theme === 'theme-afluent';
  const isIEMS =
    benefitDetail.benefit_code === 'EMC_Emergency_Medical_Assistance';

  const termsDocuments =
    benefitDetail &&
    benefitDetail.documents &&
    benefitDetail.documents
      .filter(document => document.type === 'TERMS_AND_CONDITIONS')
      .filter(document => {
        if (document.valid_until) {
          return isSameOrBeforeDay(
            new Date(`${document.valid_until}T00:00:00`),
            new Date()
          );
        }
        return true;
      })
      .sort(sortByValidUntil);

  useEffect(() => {
    const openChatItems = Array.from(
      document.getElementsByClassName('openChat')
    );
    if (openChatItems && openChatItems.length > 0) {
      openChatItems.map(item => {
        item.addEventListener('click', clickChat);
      });
    }
  }, [document]);

  useEffect(() => {
    const openExpectedDocumentsItem = document.getElementById(
      'openExpectedDocuments'
    );
    const expectedDocumentsSection = document.getElementById('tabSection');
    if (
      openExpectedDocumentsItem &&
      expectedDocuments &&
      setSelectedTab &&
      expectedDocumentsSection
    ) {
      openExpectedDocumentsItem.addEventListener('click', () => {
        setSelectedTab('EXPECTED_DOCUMENTS');

        expectedDocumentsSection.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'nearest',
        });
      });
    }
  }, [document, expectedDocuments]);

  const LinkArea = (
    <div className={styles.linkArea}>
      {termsDocuments && termsDocuments[1] && (
        <div key={termsDocuments[1].content_url} style={{ display: 'block' }}>
          <Link
            href={termsDocuments[1].content_url}
            label={
              <span
                dangerouslySetInnerHTML={{
                  __html: intl.SEE_PREVIOUS_PDF(
                    new Date(`${termsDocuments[1].valid_until}T00:00:00`)
                  ),
                }}
              ></span>
            }
          />
        </div>
      )}
      {termsDocuments && termsDocuments[0] && (
        <div key={termsDocuments[0].content_url} style={{ display: 'block' }}>
          <Link href={termsDocuments[0].content_url} label={intl.SEE_PDF} />
        </div>
      )}
    </div>
  );

  if (isNewVersionDetail) {
    return (
      <>
        <div
          dangerouslySetInnerHTML={{
            __html: `<div class="theme ${theme}">${sanitize(
              benefitDetail.long_description
            )}</div>`,
          }}
        ></div>
        {ENABLE_UPSELLING && isMX && isAfluent && isIEMS && (
          <BenefitBlueRetangle />
        )}
        {LinkArea}
      </>
    );
  }

  return (
    <>
      <article
        className={` ${styles.description} ${styles[theme]}`}
        dangerouslySetInnerHTML={{
          __html:
            (descriptionDivs &&
              sanitize(
                descriptionDivs && descriptionDivs[0] && descriptionDivs[0]
              )) ||
            (benefitDetail && sanitize(benefitDetail.long_description)),
        }}
      ></article>

      {descriptionDivs && descriptionDivs[1] && (
        <div
          className={`${styles.terms} ${styles[theme]}`}
          dangerouslySetInnerHTML={{
            __html: (descriptionDivs && sanitize(descriptionDivs[1])) || <></>,
          }}
        />
      )}

      {LinkArea}
    </>
  );
};

export default BenefitContent;
