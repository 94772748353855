/* eslint-disable no-console */
import React, { useState, useEffect, useContext } from 'react';
import styles from './BenefitsDetail.module.scss';
import { withRouter } from 'react-router-dom';
import { productsAndBenefitsServices } from '../../services/index';
import { IntlContext } from '../../intl';
import Banner from './banner/Banner';
import NavigateBenefits from './navigateBenefits/NavigateBenefits';
import RelatedOffers from '../../components/RelatedOffers/RelatedOffers';
import KnowBenefits from '../../components/Sections/KnowBenefits/KnowBenefits';
import * as translations from './intl';
import Loading from '../../components/Loading/Loading';
import RelatedSpecialBenefits from '../../components/RelatedSpecialBenefits/RelatedSpecialBenefits';
import { StateContext } from '../../components/StateContextParent/StateContextParent';
import { ThemeContext } from '../../themes/ThemeContextParent/ThemeContextParent';
import { PublicContext } from '../../components/PublicContextParent/PublicContextParent';
import ModalInformation from '../../components/ModalInformation/ModalInformation';
import ModalCreditCard from '../../assets/images/modal/modal_image_credit_card.png';
import {
  ENABLE_FAQ_API,
  ENABLE_OFFERS,
  ENABLE_SPECIAL_BENEFITS,
  HIDDEN_BENEFITS,
} from '../../utils/environments_variables';
import UpgradeMe from '../../components/UpgradeMe/UpgradeMe';
import { checkAcessCross } from '../../utils/verifyAccessCrosselling';
import { HIDDEN, SPECIAL_BENEFIS } from '../../utils/benefitCategory';
import Footer from '../DownloadApp/Footer/Footer';
import { checkAccessDownloadApp } from '../../utils/downloadAppAccess';
import BenefitBluebox from './benefitBluebox/BenefitBluebox';
import MainContent from './MainContent';
import { BenefitsContext } from '../../components/BenefitsContextParent/BenefitsContextParent';

const ERROR_NOT_FOUND = 'ERROR_NOT_FOUND';

const loadData = async (
  product_id,
  benefit_id,
  idiom,
  setBenefitDetail,
  setLoading
) => {
  setLoading(true);
  try {
    const response =
      await productsAndBenefitsServices.getBenefitsDetailByProductV2(
        product_id,
        benefit_id,
        idiom,
        false
      );

    let benefitDetail = { ...response.data };

    setBenefitDetail(benefitDetail);
  } catch (error) {
    if (error && error.response && error.response.status === 404) {
      setBenefitDetail(ERROR_NOT_FOUND);
    } else {
      setBenefitDetail({});
    }
  }
  setLoading(false);
};

const loadList = async (product_idBinOrPan, idiom, setBenefitsList) => {
  try {
    const response = await productsAndBenefitsServices.getBenefitsByProduct(
      product_idBinOrPan,
      idiom,
      true,
      false
    );
    const list = response.data
      .filter(
        benefit =>
          benefit.categories !== HIDDEN &&
          benefit.categories !== SPECIAL_BENEFIS &&
          !HIDDEN_BENEFITS.includes(benefit.external_reference)
      )
      .map(benefit => ({
        benefit_id: benefit.benefit_id,
        name: benefit.name,
        external_reference: benefit.external_reference,
      }));
    setBenefitsList(list);
  } catch (error) {}
};

const getNextBenefit = (index, benefitList, product_id, isLogged) => {
  if (benefitList.length === 0 || index === -1) {
    return { name: null, link: '/' };
  }

  let benefit = {};
  if (index === benefitList.length - 1) {
    benefit = benefitList[0];
  } else {
    benefit = benefitList[index + 1];
  }
  return {
    name: benefit.name,
    link: isLogged
      ? `/your-benefits/${benefit.benefit_id}`
      : `/benefits/${product_id}/${benefit.benefit_id}`,
  };
};
const getPreviousBenefit = (index, benefitList, product_id, isLogged) => {
  if (benefitList.length === 0 || index === -1) {
    return { name: null, link: '/' };
  }

  let benefit = {};

  if (index === 0) {
    benefit = benefitList[benefitList.length - 1];
  } else {
    benefit = benefitList[index - 1];
  }
  return {
    name: benefit.name,
    link: isLogged
      ? `/your-benefits/${benefit.benefit_id}`
      : `/benefits/${product_id}/${benefit.benefit_id}`,
  };
};

const setPublicCard = async (setProductActive, country, idiom, product_id) => {
  try {
    const { data } = await productsAndBenefitsServices.getProductsByCountry(
      country,
      idiom,
      false
    );

    const productFiltered = data.find(
      product => product.product_id === product_id
    );

    if (productFiltered) {
      setProductActive(productFiltered);
    } else {
      setProductActive(data[0] || {});
    }
  } catch (error) {
    console.log(error);
    setProductActive({});
  }
};

const BenefitsDetail = props => {
  const { match, history } = props;
  let { product_id, benefit_id } = match.params;
  const [benefitDetail, setBenefitDetail] = useState({});
  const [benefitsList, setBenefitsList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { actions } = useContext(StateContext);
  const { productActive, setProductActive } = useContext(PublicContext);
  const { hasSubscriptionBenefits } = useContext(BenefitsContext);
  const { getGlobalTheme } = useContext(ThemeContext);
  const paramsArray = match.path.split('/');

  if (
    paramsArray[1] === 'claim-type' &&
    paramsArray[2] === 'Teleconsultation'
  ) {
    benefit_id = 'Teleconsultation';
  }

  const { idiomForApi, translate, definitiveIdiom, idiom, country } =
    useContext(IntlContext);
  const { utils } = useContext(StateContext);
  const intl = translate(translations);
  const idiom_API = idiomForApi();
  const benefitIndex = benefitsList
    ? benefitsList.findIndex(
        benefit =>
          String(benefit.benefit_id).toLowerCase() ===
            String(benefit_id).toLowerCase() ||
          String(benefit.external_reference).toLowerCase() ===
            String(benefit_id).toLowerCase()
      )
    : -1;
  const theme = getGlobalTheme();
  const isLogged = utils.isUserLogged();
  const card_idBinOrPan = isLogged ? utils.getBin(true) : product_id;
  const cardHasVMO = benefitsList.find(
    benefit => benefit.benefit_id === 'Teleconsultation_24'
  );
  const isDownloadType =
    benefitDetail.redeem_info &&
    benefitDetail.redeem_info.redeem.type === 'download';
  const isDefaultType =
    benefitDetail.redeem_info &&
    (benefitDetail.redeem_info.redeem.type === 'default' ||
      benefitDetail.redeem_info.redeem.type === 'visa_airport_companion');

  const isTripAssistanceAndBR =
    idiom === 'pt_BR' && benefitDetail.benefit_id === 'trip_assistance_65';

  useEffect(() => {
    if (benefitIndex !== -1) {
      loadData(
        card_idBinOrPan,
        benefitsList[benefitIndex].benefit_id,
        idiom_API,
        setBenefitDetail,
        setLoading
      );
    }
  }, [card_idBinOrPan, benefitIndex, definitiveIdiom]);

  useEffect(() => {
    loadList(card_idBinOrPan, idiom_API, setBenefitsList);
  }, [card_idBinOrPan, definitiveIdiom]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [card_idBinOrPan, benefit_id]);

  useEffect(() => {
    if (!(productActive && productActive.product_id) && !isLogged) {
      setPublicCard(setProductActive, country, idiom_API, product_id);
    }
  }, []);

  useEffect(() => {
    let availableCrossBenefit = false;
    if (isLogged) {
      availableCrossBenefit = checkAcessCross(
        benefitDetail,
        utils.getSelectedCard(),
        utils.getEmail()
      );
    }
    if (
      (!availableCrossBenefit &&
        benefitDetail &&
        benefitsList.length > 0 &&
        benefitIndex === -1) ||
      benefitDetail === ERROR_NOT_FOUND
    ) {
      actions.modal.showModal(
        false,
        false,
        <ModalInformation
          type="error"
          message={intl.MESSAGE_MODAL_BENEFIT_NOT_ELEGIBILITY(benefitDetail)}
          image={ModalCreditCard}
          textBtn={intl.BTN_VIEW_BENEFITS}
          clickBtn={() => {
            actions.modal.closeModal();
            history.push('/your-benefits');
          }}
          textBtnDeny={intl.BTN_VIEW_CARDS}
          clickBtnDeny={() => {
            actions.modal.closeModal();
            history.push('/profile/account#cards');
          }}
          subtitle={intl.SUBTITLE_MODAL_NOT_ELEGIBILITY}
        ></ModalInformation>,
        true
      );
    }
  }, [benefitDetail, benefitsList, benefitIndex]);

  return (
    <>
      <div
        className={`${styles.containerBenefitsDetail} ${styles.newVersionDetail}`}
      >
        <Banner
          benefitDetail={benefitDetail}
          loadingBanner={loading}
          isLogged={isLogged}
          cardHasVMO={cardHasVMO}
          isNewVersionDetail={isDownloadType || isDefaultType}
        />
        <div className={styles.boxContainer}>
          <BenefitBluebox
            benefitDetail={benefitDetail}
            isDownloadType={isDownloadType}
            cardHasVMO={!!cardHasVMO}
          />
          <div
            id="mainContent"
            className={`${styles.infoContainer} ${styles[theme]}${
              ENABLE_FAQ_API ? ` ${styles.infoContainerWithTabBar}` : ''
            }`}
          >
            {loading ? (
              <div className={styles.loadingContainer} role="loadingInfo">
                <Loading />
              </div>
            ) : (
              <MainContent
                benefitDetail={benefitDetail}
                isNewVersionDetail={isDownloadType || isDefaultType}
              />
            )}
          </div>
        </div>
        <div className={styles.boxPage}>
          <NavigateBenefits
            previousLink={
              getPreviousBenefit(
                benefitIndex,
                benefitsList,
                card_idBinOrPan,
                isLogged
              ).link
            }
            nextLink={
              getNextBenefit(
                benefitIndex,
                benefitsList,
                card_idBinOrPan,
                isLogged
              ).link
            }
            borderBottom={
              (isDownloadType && !isTripAssistanceAndBR) || isDefaultType
            }
          />
          {checkAccessDownloadApp(country) && <Footer />}
          {ENABLE_OFFERS && <RelatedOffers />}
          {ENABLE_SPECIAL_BENEFITS && <RelatedSpecialBenefits />}
          <div className={!hasSubscriptionBenefits ? styles.marginTop : ''}>
            {isLogged && <UpgradeMe />}
          </div>
        </div>
        {!isLogged && <KnowBenefits />}
      </div>
    </>
  );
};

export default withRouter(BenefitsDetail);
