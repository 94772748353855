import React, { Component, createRef } from 'react';
import AcordeonSession from '../../../../../components/AcordeonSession/AcordeonSession';
import GeneralInformation from '../../../../Claims/Generate/ClaimFillForm/Form/GeneralInformation/GeneralInformation';
import { generalInformationFormInitialValues } from '../../../../Claims/Generate/ClaimFillForm/Form/GeneralInformation/initialValues';
import Dependents from '../Dependents/Dependents';
import { dependentsInitialValues } from '../Dependents/initialValues';
import { pt_BR } from '../../../../../intl/idioms';
import TripInformation from './TripInformation/TripInformation';
import { tripInitialValues } from './TripInformation/initialValues';
import LegalAdviceAndConsent from '../LegalAdviceAndConsent/LegalAdviceAndConsent';
import ModalInformation from '../../../../../components/ModalInformation/ModalInformation';
import {
  DEPENDENTS_MANDATORY_INFORMATION_MISSING,
  DEPENDENTS_MANDATORY_INFORMATION_MISSING_SUBTITLE,
} from './intl';

export default class Travel extends Component {
  constructor(props) {
    super(props);
    this.keys = ['policy', 'dependents', 'legal'];
    this.isBrazil = this.props.idiom === pt_BR;

    this.certificateType = props.certificateType ?? false;
    this.submitRefs = {
      policy: createRef(),
      dependents: createRef(),
      legal: createRef(),
    };

    this.accordeonRefs = {
      policy: createRef(),
      dependents: createRef(),
      legal: createRef(),
    };

    this.initialValues = {
      policy: generalInformationFormInitialValues(
        props.loadedData.cardHolder,
        '',
        this.props.idiom, //idiom
        true
      ),
      dependents: dependentsInitialValues(
        props.loadedData.dependents,
        this.props.idiom,
        true
      ),
    };

    this.keys.push('tripInformation');
    this.submitRefs.tripInformation = createRef();
    this.accordeonRefs.tripInformation = createRef();
    this.initialValues.tripInformation = tripInitialValues(this.props.idiom);
    this.state = {
      isCardHolder: true,
      listAccordeon: {
        policy: true,
        dependents: false,
        tripInformation: false,
        legal: false,
      },
    };
  }

  componentDidMount() {
    this.props.setInitialValuesCompare(this.initialValues);
  }

  setStatusAccordeon = name => {
    const newList = {
      policy: false,
      dependents: false,
      tripInformation: false,
      legal: false,
    };
    newList[name] = !this.state.listAccordeon[name];
    this.setState({ listAccordeon: newList });
  };

  changeCardHolder(isCardHolder) {
    this.setState({ isCardHolder: isCardHolder });
  }

  render() {
    const { initialValues, isBrazil } = this;
    return (
      <div>
        <AcordeonSession
          id="policy"
          number={1}
          startsOpened
          title={this.props.intl.TITLE_TRAVELLER_INFORMATION}
          openButtonRef={this.accordeonRefs.policy}
          status={this.state.listAccordeon['policy']}
          setStatus={() => this.setStatusAccordeon('policy')}
          next={async () => {
            const responseOK = await this.props.openTargetAcordeon(
              'policy',
              'dependents',
              true
            );
            if (responseOK) {
              await this.props.generalInformationCheck();
            }
          }}
          back={() =>
            this.props.history.push('/certificates/previous-certificates')
          }
        >
          <GeneralInformation
            policySubmitRef={this.submitRefs.policy}
            updateParent={this.props.updateForm.bind(this, 'policy')}
            initialValues={initialValues.policy}
            showIsCardHolderArea={true}
            showIsIssuanceArea={isBrazil}
            isCertificate
            isTravelCertificate
            productName={this.props.selectedCard.name}
            changeCardHolder={this.changeCardHolder.bind(this)}
            prefixOptions={this.props.prefixOptions}
          />
        </AcordeonSession>
        <AcordeonSession
          id="dependents"
          number={2}
          title={this.props.intl.TITLE_ADITIONAL_TRAVELERS}
          openButtonRef={this.accordeonRefs.dependents}
          status={this.state.listAccordeon['dependents']}
          setStatus={() => this.setStatusAccordeon('dependents')}
          next={async () => {
            try {
              await this.props.openTargetAcordeon(
                'dependents',
                'tripInformation',
                true,
                true
              );
            } catch (error) {
              this.props.actions.modal.showModal(
                false,
                true,
                <ModalInformation
                  type="error"
                  message={
                    DEPENDENTS_MANDATORY_INFORMATION_MISSING[this.props.idiom]
                  }
                  subtitle={
                    DEPENDENTS_MANDATORY_INFORMATION_MISSING_SUBTITLE[
                      this.props.idiom
                    ]
                  }
                />,
                true,
                false
              );
              this.props.setDetailedError(error);
            }
          }}
          back={() =>
            this.props.openTargetAcordeon('dependents', 'policy', false)
          }
          isForm={false}
        >
          <Dependents
            dependentsSubmitRef={this.submitRefs.dependents}
            updateParent={this.props.updateForm.bind(this, 'dependents')}
            initialValues={initialValues.dependents}
            isCardHolder={this.state.isCardHolder}
            isCertificate
            changeCardHolder={this.changeCardHolder.bind(this)}
            detailedError={this.props.detailedError}
            setDetailedError={this.props.setDetailedError}
          />
        </AcordeonSession>
        <AcordeonSession
          id="tripInformation"
          number={3}
          title={this.props.intl.TITLE_TRIP_INFORMATION}
          openButtonRef={this.accordeonRefs.tripInformation}
          status={this.state.listAccordeon['tripInformation']}
          setStatus={() => this.setStatusAccordeon('tripInformation')}
          next={() =>
            this.props.openTargetAcordeon('tripInformation', 'legal', true)
          }
          back={() =>
            this.props.openTargetAcordeon(
              'tripInformation',
              'dependents',
              false
            )
          }
        >
          <TripInformation
            tripSubmitRef={this.submitRefs.tripInformation}
            updateParent={this.props.updateForm.bind(this, 'tripInformation')}
            initialValues={initialValues.tripInformation}
            isBrazil={isBrazil}
            isCertificate
          />
        </AcordeonSession>
        <AcordeonSession
          id="legal"
          title={this.props.intl.TITLE_BENEFIT_TERMS}
          openButtonRef={this.accordeonRefs.legal}
          accordeon="legal"
          status={this.state.listAccordeon['legal']}
          setStatus={() => this.setStatusAccordeon('legal')}
          txtBtnNext={this.props.intl.TEXT_SUBMIT_CERTIFICATE}
          next={this.props.submit}
          back={() =>
            this.props.openTargetAcordeon('legal', 'tripInformation', false)
          }
        >
          <LegalAdviceAndConsent
            updateParent={this.props.updateForm.bind(null, 'legal')}
            legalSubmitRef={this.submitRefs.legal}
            certificateType={this.certificateType ?? false}
          />
        </AcordeonSession>
      </div>
    );
  }
}
