/* eslint-disable no-console */
import { useEffect, useState, useContext, Fragment } from 'react';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import { formatLinkWithIdiomAndCountry } from '../../../../utils/stringUtils';
import { certificatesServices, claimsServices } from '../../../../services';
import { StateContext } from '../../../StateContextParent/StateContextParent';
import { withRouter } from 'react-router-dom';
import { IntlContext } from '../../../../intl';
import * as translations from './intl';
import Button from '../../../../componentsV2/Button/Button';
import styles from './ButtonProfile.module.scss';
import WhatsappIcon from '../../../../assets/icons/WhatsappIcon';
import { ENABLE_TRIP_ASSISTANCE } from '../../../../utils/environments_variables';
import { CINEPOLIS_GOLD, isTripAssistance } from '../../../../utils/benefits';

const TELECONSULTATION = 'Teleconsultation';
const CONCIERGE = 'Concierge';
const ANDROID = 'android';
const IOS = 'ios';

function getDescriptionAndPdfFormated(benefit, idiom, country) {
  let pdf_url = '';
  let pdf_url_certificate = '';
  let pdf_url_claim = '';
  let redeem = '';
  if (benefit.documents) {
    for (let i = 0; i < benefit.documents.length; i++) {
      const pdf = benefit.documents[i];
      if (pdf.name) {
        switch (pdf.name) {
          case 'pdf_url':
            pdf_url = pdf.content_url ? pdf.content_url : '';
            break;
          case 'pdf_url_certificate':
            pdf_url_certificate = pdf.content_url ? pdf.content_url : '';
            break;
          case 'pdf_url_claim':
            pdf_url_claim = pdf.content_url ? pdf.content_url : '';
            break;
          default:
            break;
        }
      } else if (pdf.type) {
        switch (pdf.type) {
          case 'BENEFIT_SUMMARY':
            pdf_url = pdf.content_url ? pdf.content_url : '';
            break;
          case 'ADDITIONAL_TERMS':
            pdf_url_certificate = pdf.content_url ? pdf.content_url : '';
            break;
          case 'TERMS_AND_CONDITIONS':
            pdf_url_claim = pdf.content_url ? pdf.content_url : '';
            break;
          default:
            break;
        }
      }
    }
  }

  const link =
    benefit.redeem_info &&
    benefit.redeem_info.redeem &&
    benefit.redeem_info.redeem &&
    benefit.redeem_info.redeem.additional_buttons;

  redeem = formatLinkWithIdiomAndCountry(link, idiom, country);
  redeem =
    redeem !== '' &&
    redeem.split(';').map(linksReddem => linksReddem.split(' - '));

  return { pdf_url, pdf_url_certificate, pdf_url_claim, redeem };
}

function IsValidButtons(external_reference) {
  return (
    external_reference !== TELECONSULTATION && external_reference !== CONCIERGE
  );
}

const loadCertificateClaim = async (
  external_reference,
  setListCertificate,
  setListClaim,
  idiom,
  binOrPan,
  setLoading
) => {
  try {
    const listCertificateTypes =
      await certificatesServices.listCertificateTypesByBinNumber(
        binOrPan,
        idiom
      );
    const filteredCertificate = matchListCertificate(
      listCertificateTypes,
      external_reference
    );
    setListCertificate(filteredCertificate);
  } catch (error) {
    console.log(error);
  }

  try {
    const listClaimTypes = await claimsServices.getClaimTypes(binOrPan, idiom);
    const filteredClaim = matchListClaim(listClaimTypes, external_reference);
    setListClaim(filteredClaim);
    setLoading(false);
  } catch (error) {
    console.log(error);
  }
};

const matchListCertificate = ({ data }, external_reference) => {
  const listFiltered = data.find(res =>
    res.covered_benefits_references.includes(external_reference)
  );
  return listFiltered;
};

const matchListClaim = ({ data }, external_reference) => {
  const listFiltered = data.find(
    res =>
      (res.external_reference &&
        res.external_reference.includes(external_reference)) ||
      (res.benefit_code && res.benefit_code.includes(external_reference))
  );
  return listFiltered;
};

const ButtonBenefits = props => {
  const { benefit, idiom, country, isDetail, css, onlyUpsell } = props;
  const [listCertificate, setListCertificate] = useState('');
  const [listClaim, setListClaim] = useState('');
  const [loading, setLoading] = useState('');

  const { utils } = useContext(StateContext);
  const { translate } = useContext(IntlContext);

  const binOrPan = utils.getBin(true);
  const buttonType = isDetail ? 'Secondary' : 'Primary';
  const buttonSurface = isDetail ? 'Inverse' : 'OnSurface';

  const intl = translate(translations);

  useEffect(() => {
    loadCertificateClaim(
      benefit.external_reference || benefit.benefit_code,
      setListCertificate,
      setListClaim,
      idiom,
      binOrPan,
      setLoading
    );
  }, []);

  let { pdf_url_certificate, pdf_url_claim, redeem } =
    getDescriptionAndPdfFormated(benefit, idiom, country);

  return (
    <>
      {!loading && (
        <>
          <div
            className={`${styles.boxButtons} ${
              isDetail ? styles.isDetail : styles.default
            } ${css ? styles[css] : ''}`}
          >
            {!isDetail && (
              <div className={styles.wrapperButton}>
                <Button
                  id={`buttonBenefit-ViewMore-${benefit.benefit_id}`}
                  type="Primary"
                  link
                  href={`/your-benefits/${benefit.benefit_id}`}
                  label={intl.VIEW_MORE}
                />
              </div>
            )}
            {redeem &&
              !isMobile &&
              redeem.map((redeemResult, i) => {
                const isVMO =
                  benefit.external_reference === TELECONSULTATION ||
                  benefit.benefit_code === TELECONSULTATION;
                const isCinepolisGold =
                  benefit.benefit_id === CINEPOLIS_GOLD ||
                  benefit.benefit_code === CINEPOLIS_GOLD;

                const shouldOpenSameTab = isVMO || isCinepolisGold;
                const isWhatsapp = redeemResult[2]
                  ? redeemResult[2].toLowerCase() === 'whatsapp'
                  : false;

                return (
                  <Fragment key={i}>
                    <Button
                      href={redeemResult[1]}
                      link
                      testId={`benefitDetailContentRedeem-${benefit.benefit_id}`}
                      internal={shouldOpenSameTab}
                      type={isWhatsapp ? 'Primary' : buttonType}
                      surface={isWhatsapp ? 'OnSurface' : buttonSurface}
                      tabIndex={-1}
                      id={`buttonBenefit-redeem-${benefit.benefit_id}_${i}`}
                      className={isWhatsapp ? styles.whatsappButton : undefined}
                      leadingIcon={
                        isWhatsapp ? (
                          <WhatsappIcon width={20} height={20} />
                        ) : undefined
                      }
                      label={`${redeemResult[0].replace(
                        /\{{benefit_name}}/g,
                        benefit.name
                      )}`}
                      trailingIcon={shouldOpenSameTab ? undefined : 'Arrow Out'}
                    />
                  </Fragment>
                );
              })}

            {redeem &&
              isMobile &&
              redeem.map((redeemResult, i) => {
                const linkName = redeemResult[0].toLowerCase();
                const haveAndroid = linkName.includes(ANDROID);
                const haveIos = linkName.includes(IOS);
                const haveNoMobile = !haveAndroid && !haveIos;

                if (
                  benefit.external_reference === 'Concierge' ||
                  benefit.benefit_code === 'Concierge'
                ) {
                  return (
                    <Button
                      link
                      href={redeemResult[1]}
                      internal={false}
                      key={i}
                      id={`buttonBenefit-Concierge-redeem-${benefit.benefit_id}`}
                      type={buttonType}
                      surface={buttonSurface}
                      tabIndex={-1}
                      label={` ${redeemResult[0].replace(
                        /\{{benefit_name}}/g,
                        benefit.name
                      )}`}
                      trailingIcon="Arrow Out"
                    />
                  );
                }
                if (isAndroid && haveAndroid) {
                  return (
                    <Button
                      link
                      href={redeemResult[1]}
                      internal={false}
                      key={i}
                      id={`buttonBenefit-android-redeem-${benefit.benefit_id}`}
                      type={buttonType}
                      surface={buttonSurface}
                      tabIndex={-1}
                      label={`${redeemResult[0].replace(
                        /\{{benefit_name}}/g,
                        benefit.name
                      )}`}
                      trailingIcon="Arrow Out"
                    />
                  );
                }
                if (isIOS && haveIos) {
                  return (
                    <Button
                      link
                      href={redeemResult[1]}
                      internal={false}
                      key={i}
                      id={`buttonBenefit-ios-redeem-${benefit.benefit_id}`}
                      type={buttonType}
                      surface={buttonSurface}
                      tabIndex={-1}
                      label={` ${redeemResult[0].replace(
                        /\{{benefit_name}}/g,
                        benefit.name
                      )}`}
                      trailingIcon="Arrow Out"
                    />
                  );
                }
                if (haveNoMobile) {
                  return (
                    <Button
                      link
                      href={redeemResult[1]}
                      internal={false}
                      key={i}
                      id={`buttonBenefit-noMobile-redeem-${benefit.benefit_id}`}
                      type={buttonType}
                      surface={buttonSurface}
                      tabIndex={-1}
                      label={`${redeemResult[0].replace(
                        /\{{benefit_name}}/g,
                        benefit.name
                      )}`}
                      trailingIcon="Arrow Out"
                    />
                  );
                }
              })}

            {pdf_url_certificate &&
              pdf_url_certificate !== '' &&
              listCertificate &&
              listCertificate.code &&
              IsValidButtons(
                benefit.external_reference || benefit.benefit_code
              ) &&
              !onlyUpsell && (
                <Button
                  id={`buttonBenefit-certificatePDF-${benefit.benefit_id}`}
                  link
                  type="Primary"
                  label={intl.GENERATE_CERTIFICATE}
                  href={`/certificate/${listCertificate?.code}`}
                />
              )}
            {pdf_url_claim &&
              pdf_url_claim !== '' &&
              listClaim &&
              IsValidButtons(
                benefit.external_reference || benefit.benefit_code
              ) &&
              !onlyUpsell && (
                <Button
                  id={`buttonBenefit-claimPDF-${benefit.benefit_id}`}
                  type={buttonType}
                  surface={buttonSurface}
                  label={intl.GENERATE_CLAIM}
                  link
                  href={`/claim-type/${
                    benefit.external_reference || benefit.benefit_code
                  }/generate`}
                  data-testid={`benefitDetailContentPdfClaim-${benefit.benefit_id}`}
                />
              )}

            {(benefit.external_reference === 'Nurse_Advice_Line' ||
              benefit.benefit_code === 'Nurse_Advice_Line') && (
              <>
                <Button
                  id={`buttonBenefit-nurse-${benefit.benefit_id}`}
                  type={buttonType}
                  surface={buttonSurface}
                  label={intl.ACCESS_VISA_NAL}
                  link
                  href="/claim-type/NurseAdviceLine"
                  testId={`benefitDetailContentPdfCertificateNurseAdviceLine-${benefit.benefit_id}`}
                />
                <Button
                  type={buttonType}
                  surface={buttonSurface}
                  label={intl.NAL_CONSULT_APPOINTMENT}
                  link
                  href="/nurse-advice-line/previous-appointments"
                  testId={`benefitDetailContentNALConsultAppointment-${benefit.benefit_id}`}
                />
              </>
            )}
            {isTripAssistance(benefit) && ENABLE_TRIP_ASSISTANCE && (
              <>
                <Button
                  id={`buttonBenefit-travel-kit-create-${benefit.benefit_id}`}
                  link
                  type={buttonType}
                  surface={buttonSurface}
                  href={`/travel-kit/create/${benefit.benefit_id}`}
                  label={intl.CREATE_TRIP_ASSISTANCE}
                />
                <Button
                  id={`buttonBenefit-my-travel-kit-${benefit.benefit_id}`}
                  type={buttonType}
                  surface={buttonSurface}
                  link
                  href="/travel-kit/"
                  label={intl.CONSULT_TRIP_ASSISTANCE}
                />
              </>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default withRouter(ButtonBenefits);
